<template>
  <v-dialog :value="dialog" persistent max-width="700" @click:outside="$emit('close')">
    <v-card>
      <v-card-title class="text-h5"> Participants </v-card-title>
      <v-text-field label="Search" @input="debounceInput" style="margin-left: 25px; margin-right: 25px; margin-top: 10px"></v-text-field>
      <div class="checkboxContainer">
        <div>
          <v-checkbox
              class="ma-0"
              justify="center"
              v-for="(item) in this.filteredUsers"
              :key="item.id"
              :value="item.id.toString()"
              :id="item.id.toString()"
              v-model="user_ids"
              :label="item.email"
          >
          </v-checkbox>
        </div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="save" :loading="isUpdateParticipantsLoading"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import debounce from "lodash/debounce";

const { mapGetters: customerGetters, mapActions: customerActions } = createNamespacedHelpers('customer');
const { mapGetters: workspacesGetters, mapActions: workspaceActions } = createNamespacedHelpers('aiWorkspace');

export default {
  name: 'AgentParticipant',
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      filteredUsers: [],
      initUsers: [],
      user_ids: [],
    };
  },
  computed: {
    ...customerGetters({
      user: 'USER',
      users: 'CUSTOMER_USERS',
      selectedCustomer: 'CUSTOMER',
    }),
    ...workspacesGetters({
      workspace: 'WORKSPACE',
      isUpdateParticipantsLoading: 'UPDATE_PARTICIPANTS_LOADING'
    })
  },
  methods: {
    ...customerActions(["getCustomerUsers"]),
    ...workspaceActions(['updateParticipants']),
    filterUsers(search) {
      this.filteredUsers = this.initUsers.filter((item) => item.email.toLowerCase().includes(search.toLowerCase()))
    },
    async save() {
      await this.updateParticipants({ workspace_id: this.workspace.id, user_ids: this.user_ids });
      this.$emit('close');
    },
    debounceInput: debounce(function (e) {
      this.search = e;
      this.filterUsers(this.search);
    }, 100)
  },
  async mounted() {
    this.user_ids = this.workspace?.Participants?.map((participant) => participant?.user_id?.toString()) || [];
    await this.getCustomerUsers();
    this.filteredUsers = this.users.filter((user) => user.id !== this.user?.id);
    this.initUsers = this.users.filter((user) => user.id !== this.user?.id);
  }
};
</script>

<style lang="scss" scoped>
@import 'participants';
</style>
