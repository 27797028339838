import { render, staticRenderFns } from "./ai-chat-settings.vue?vue&type=template&id=f8db6594&scoped=true"
import script from "./ai-chat-settings.vue?vue&type=script&lang=js"
export * from "./ai-chat-settings.vue?vue&type=script&lang=js"
import style0 from "./ai-chat-settings.vue?vue&type=style&index=0&id=f8db6594&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8db6594",
  null
  
)

export default component.exports