<template>
  <v-dialog :value="dialog" persistent max-width="1000" @click:outside="$emit('close')">
    <v-card>
      <v-card-title class="text-h5"> Create Agent </v-card-title>
      <v-row class="CreateWorkspace__header">
        Name
      </v-row>
      <v-row class="CreateWorkspace__systemPrompt">
        <v-col>
          <v-text-field
              class="TextArea"
              outlined
              dense
              :placeholder="'Name'"
              hide-details="auto"
              novalidate
              :rows="5"
              v-model="name"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="CreateWorkspace__header">
        Description
      </v-row>
      <v-row class="CreateWorkspace__systemPrompt">
        <v-col>
          <v-textarea
              class="TextArea"
              outlined
              dense
              :placeholder="'Description'"
              hide-details="auto"
              novalidate
              :rows="5"
              v-model="description"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Close </v-btn>
        <v-btn text @click="createNewWorkspace" color="primary" :loading="createWorkspaceLoading"> Create </v-btn>
      </v-card-actions>
    </v-card>
    <tools-select :dialog="isToolSelectDialog" :toolSets="toolSets" @close="isToolSelectDialog = false" @toolsChanged="(data) => { toolSets = data}"/>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ToolsSelect from "@/views/ai-workspaces/create-workspace/tools-select/tools-select.vue";
import lodashGet from "lodash/get";
const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
} = createNamespacedHelpers('AIChat');

const { mapActions: workspaceActions, mapGetters: workspaceGetter } = createNamespacedHelpers('aiWorkspace');
const { mapGetters: customerGetters } =
    createNamespacedHelpers('customer');

export default {
  name: 'CreateAgent',
  components: {
    ToolsSelect,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxTokens: 2000,
      systemPromptText: '',
      selectedSystemPrompt: null,
      includeAllHistory: false,
      toolSets: [],
      isToolSelectDialog: false,
      name: '',
      description: '',
      historyLimit: 15,
      models: [
        {
          label: 'Claude V3 Sonnet',
          value: 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        {
          label: 'Claude 3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0'
        },
        {
          label: 'Claude 3.5 Sonnet v2',
          value: 'anthropic.claude-3-5-sonnet-20241022-v2:0'
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0'
        },
        {
          label: 'Claude V3.5 Haiku',
          value: 'anthropic.claude-3-5-haiku-20241022-v1:0'
        },
      ],
      selectedModel: 'anthropic.claude-3-haiku-20240307-v1:0',
    };
  },
  computed: {
    ...AIChatGetters({
      systemPrompts: 'SYSTEM_PROMPTS',
      systemPromptLoading: 'SYSTEM_PROMPT_LOADING',
      updateSystemPromptLoading: 'UPDATE_SYSTEM_PROMPT_LOADING'
    }),
    ...workspaceGetter({
      createWorkspaceLoading: 'WORKSPACE_UPDATE_LOADING'
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
      customers: 'CUSTOMERS',
      selected_customer: 'SELECTED_CUSTOMER',
      featureFlags: 'FEATURE_FLAGS',
    }),
  },
  methods: {
    ...AIChatActions(['getToolSets', 'getSystemPrompts', 'updateSystemPrompt', 'createSystemPrompt']),
    ...workspaceActions(['createWorkspace']),
    toolsChanged(data) {
      this.toolSets = data;
    },
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    async saveSystemPrompt() {
      if(this.selectedSystemPrompt?.id) {
        await this.updateSystemPrompt({
          systemPromptId: this.selectedSystemPrompt.id,
          prompt: this.systemPromptText,
        });
        return;
      }
      const prompt = await this.createSystemPrompt({
        prompt: this.systemPromptText,
        name: this.systemPromptText,
      })
      this.systemPrompts.push(prompt);
      this.selectedSystemPrompt = prompt;
      this.systemPromptText = prompt.prompt;
    },
    async updateSelectedSystemPrompt(value) {
      const systemPrompt = this.systemPrompts.find((prompt) => prompt.id === value);
      this.systemPromptText = systemPrompt.prompt;
      this.selectedSystemPrompt = systemPrompt;
    },
    async createNewWorkspace() {
        const workspace = await this.createWorkspace({
          toolSetIds: this.toolSets.map(tool => tool.id),
          name: this.name,
          description: this.description,
          model: this.selectedModel,
          systemPromptId: this.selectedSystemPrompt?.id,
          maxTokens: this.maxTokens,
          historyLimit: this.includeAllHistory ?  null : this.historyLimit,
        })
        this.$router.push({ path: `agents/${workspace.id}`})
    }
  },
  async created() {
    this.getSystemPrompts();
  }
};
</script>

<style lang="scss" scoped>
@import './create-workspace';
</style>
