<template>
  <div class="Promts">
    <v-row class="Promts__filter-row">
      <v-col>
        <v-btn text small color="primary" @click="isDialogOpen = true">
          + Add Action
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
        class="Promts__data-table elevation-0"
        outlined
        item-key="id"
        :item-class="() => 'Tools__data-table-row'"
        :items="Tools"
        :headers="headers"
        :loading="fetchToolsLoading || deleteToolLoading"
        @click:row="handleGoToTool"
    >
      <template v-slot:[`item.type`]="{ item }">
        <v-chip small>
          <strong>
            {{ item.type?.toUpperCase() }}
          </strong>
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="error" @click.stop="triggerDelete(item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <action-prompt
        v-model="showDeleteToolModal"
        v-if="showDeleteToolModal"
        action-text="Delete"
        :action="removeTool"
        :loading="deleteToolLoading"
        header="Delete Action"
        :body="deleteMessageBody"
        action-type="error"
    />

    <CreateTool :dialog="isDialogOpen" @close="isDialogOpen = false" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ActionPrompt from '@/components/modals/action-prompt/action-prompt.vue';
import CreateTool from '@/components/modals/create-tool/create-tool.vue';

const {  mapGetters: toolsGetters, mapActions: toolsActions } =
    createNamespacedHelpers('promptsTools');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'Action',
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      toolCount: null,
      toolId: null,
      showDeleteToolModal: false,
      isDialogOpen: false,
    };
  },
  components: {
    ActionPrompt,
    CreateTool
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.fetchTools();
    },
  },
  computed: {
    ...toolsGetters({
      Tools: 'TOOLS',
      fetchToolsLoading: 'FETCH_TOOLS_LOADING',
      deleteToolLoading: 'DELETE_TOOL_LOADING',
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    deleteMessageBody() {
      const lineOne = 'Are you sure you want to delete this Action?';
      const lineTwo = `There are ${this.ToolCount} workflows using this Action.`;
      let message = lineOne;
      if (this.ToolCount) message += `\n\n${lineTwo}`;
      return message;
    },
  },
  mounted() {
    this.fetchTools();
  },
  methods: {
    ...toolsActions(['fetchTools', 'deleteTool']),
    triggerDelete({ id, count }) {
      this.ToolCount = count;
      this.ToolId = id;
      this.showDeleteToolModal = true;
    },
    async removeTool() {
      await this.deleteTool(this.ToolId);
      this.ToolCount = null;
      this.ToolId = null;
      this.showDeleteToolModal = false;
    },
    handleGoToTool(item) {
      this.$router.push({ path: `/actions/${item.id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'tools-page';
</style>
