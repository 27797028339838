<template>
  <div class="Tool">
    <Loader text="Loading Tool" v-if="!Tool" />
    <div>
      <header class="Tool__header">
        <div
          class="Tool__header-container d-flex justify-space-between align-center"
        >
          <div class="d-flex align-center">
            <p class="Tool__header-title">
              <router-link
                to="/actions"
                tag="span"
                class="Tool__header-title-link"
              >
                Actions
              </router-link>
              <v-icon small class="Tool__header-title-icon">
                mdi-chevron-right
              </v-icon>
              <span class="Tool__header-title-name" v-if="Tool.name">
                {{ Tool.name }}
              </span>
              <span class="Tool__header-title-no-name" v-else>
                Action name here
              </span>
            </p>
          </div>

          <div class="d-flex justify-space-between">
            <div class="Tool__header-button-divider"></div>
            <v-btn
              color="primary"
              elevation="0"
              :disabled="editToolLoading"
              @click="save"
              :loading="editToolLoading"
            >
              Save Action
            </v-btn>
          </div>
        </div>
      </header>

      <div class="Tool__main-content">
        <v-row>
          <v-col>
            <v-card :width="800" class="SideSettings" :elevation="1">
              <ToolSettings />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import lodashSet from 'lodash/set';
import Loader from '@/components/loader/loader.vue';
import ToolSettings from './tool-settings/tool-settings.vue';

const {
  mapGetters: toolsGetters,
  mapActions: toolsActions,
  mapMutations: toolMutations,
} = createNamespacedHelpers('promptsTools');
export default {
  name: 'ToolDetails',
  components: {
    ToolSettings,
    Loader,
  },
  computed: {
    ...toolsGetters({
      Tool: 'TOOL',
      fetchToolLoading: 'FETCH_TOOL_LOADING',
      editToolLoading: 'UPDATE_TOOL_LOADING',
    }),
    availableInputFields() {
      return [...this.inputs, ...this.attachments];
    },
  },
  async mounted() {
    const { toolId } = this.$route.params;
    if (toolId !== 'new') {
      await this.fetchTool(toolId);
    }
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    ...toolMutations({
      setTool: 'SET_TOOL',
    }),
    ...toolsActions(['fetchTool', 'updateTool']),
    editTool(prop, value) {
      const Tool = cloneDeep(this.Tool);
      lodashSet(Tool, prop, value);
      this.setTool(Tool);
    },
    save() {
      const updatedTool = {
        ...this.Tool,
      };
      return this.updateTool(updatedTool);
    },
  },
};
</script>

<style lang="scss">
@import './tool';
</style>
