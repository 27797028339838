<template>
  <v-dialog class="AttachDialog" :value="dialog" persistent max-width="1000" @click:outside="$emit('close')">
    <v-card class="AttachDialog__card">
      <v-card-title class="text-h5"> Attach from OneDrive </v-card-title>
      <v-col>
        <v-row>
          <v-switch label="Use share URL" v-model="isShareUrl">
            Use Share URL
          </v-switch>
        </v-row>

        <v-row class="AttachDialog__auth_settings">
          <v-autocomplete
              class="mb-n4"
              outlined
              dense
              v-model="authentication"
              :items="authentications"
              item-text="name"
              item-value="id"
              label="Authentication"
              @click:append-outer="getAuth"
              :append-outer-icon="locationLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
              clearable
          ></v-autocomplete>
        </v-row>

        <v-row v-if="isShareUrl">
          <v-text-field
              v-model="shareUrl"
              outlined
              dense
              label="URL"
          >

          </v-text-field>
        </v-row>

        <v-row class="AttachDialog__file_settings" v-if="!isShareUrl">
          <v-autocomplete
              return-object
              class="mb-3"
              outlined
              dense
              v-model="file"
              :value="fileName"
              item-text="name"
              item-value="id"
              :items="driveFiles"
              label="File"
              @click:append-outer="listFiles"
              :append-outer-icon="locationLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
              clearable
          >
            <template v-slot:item="{ props, item }">
              <v-list-item-content
                  v-bind="props"
                  :subtitle="item.mimeType"
                  :title="item.name"
              >
                <div style="display: flex; flex-flow: row" v-bind="props">
                  <div class="AttachDialog_itemIcon">
                    <document-icon style="width: 20px; height: 20px" v-if="item.mimeType.includes('document') && !item.mimeType.includes('sheet')" class="icon"/>
                    <spreadsheetIcon style="width: 20px; height: 20px" v-if="item.mimeType.includes('spreadsheet') || item.mimeType.includes('sheet')" class="icon"/>
                  </div>
                  <span>
                    {{ item.name }}
                  </span>
                </div>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-row>
      </v-col>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn text @click="createKnowledge" color="primary"> Add </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import lodashGet from "lodash/get";
import {createNamespacedHelpers} from "vuex";
import documentIcon from '@/views/ai-workspaces/workspace/knowledge/icons/document.vue';
import spreadsheetIcon from '@/views/ai-workspaces/workspace/knowledge/icons/spreadsheet.vue';

const { mapGetters: credentialsGetters, mapActions: credentialsActions } = createNamespacedHelpers('credentials');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: workspacesGetters, mapActions: workspacesActions } = createNamespacedHelpers('aiWorkspace');


const { mapActions: workflowActions } =
    createNamespacedHelpers('workflows/list');

export default {
  name: 'AttachOneDrive',
  components: {spreadsheetIcon, documentIcon},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authentication: null,
      location: null,
      file: null,
      driveFolders: [],
      driveFiles: [],
      locationLoading: false,
      fileName: null,
      isShareUrl: false,
      shareUrl: null,
      fileData: {
        fileName: null,
        locationName: null,
        driveId: null,
      }
    };
  },
  watch: {
    async 'selectedCustomer.customer_id'() {
      await this.fetchAuthentications();
    },
  },
  computed: {
    ...credentialsGetters({
      authentications: 'AUTHENTICATIONS',
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER'
    }),
    ...workspacesGetters({
      workspace: 'WORKSPACE',
    })
  },
  methods: {
    ...credentialsActions(['fetchAuthentications']),
    ...workflowActions(['outlookAction']),
    ...workspacesActions(['addWorkspaceKnowledge']),
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    getAuth() {
      this.fetchAuthentications({ type: 'outlook' })
    },
    async listFiles() {
      if (!this.authentication) {
        return;
      }
      this.locationLoading = true;
      try {
        const data = {
          authenticationId: this.authentication,
          action: 'list_documents_and_spreadsheets_in_folder',
          location: this.fileData.locationName,
        };
        const resp = await this.outlookAction(data);

        if (resp && resp.result) {
          this.driveFiles = resp.result;
        }
      } finally {
        this.locationLoading = false;
      }
    },
    async createKnowledge() {
      let externalLocation = `${this.file?.driveId}/${this.file?.folderId}/${this.file?.id}`;
      if(this.isShareUrl) {
        externalLocation = this.shareUrl;
      }
      await this.addWorkspaceKnowledge({
        workspaceId: this.workspace.id,
        credentialsIds: this.authentication,
        name: this.file?.name,
        mimeType: this.file?.mimeType,
        externalLocation: externalLocation,
        type: 'onedrive',
        isExternal: true,
      })
      this.$emit('close')
    }
  },
  async created() {
    await this.fetchAuthentications({ type: 'outlook' });
  }
};
</script>

<style lang="scss" scoped>
@import './index';
</style>
