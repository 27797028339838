<template>
  <div class="AutomationSidebar">
    <v-alert
      dense
      type="warning"
      v-if="workflowEdited"
      rounded="0"
      class="AutomationSidebar__workflow-edited-alert"
    >
      You have unsaved changes on this workflow
    </v-alert>

    <v-tabs v-model="tab" grow>
      <v-tab v-for="tab in tabs" :key="tab">{{ tab }}</v-tab>
      <v-tab-item :value="tabs.indexOf('Build')">
        <StartSettings />
      </v-tab-item>
      <v-tab-item :value="tabs.indexOf('Run')">
        <test-block />
      </v-tab-item>
    </v-tabs>

    <settings-block />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { actionsTypes } from '@/util/action-types';
import cloneDeep from 'lodash/cloneDeep';
import StartSettings from '@/components/connector-settings/start-settings/start-settings.vue';
import SettingsBlock from './settings-block/settings-block';
import TestBlock from './test-block/test-block';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'AutomationSidebar',
  components: {
    StartSettings,
    SettingsBlock,
    TestBlock,
  },
  data() {
    return {
      tabs: ['Build', 'Run'],
      tab: 0,
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
    }),
    ...workflowDetailsGetters({
      startSettings: 'START_SETTINGS',
      workflow: 'WORKFLOW',
      workflowEdited: 'WORKFLOW_EDITED',
      workflowRunning: 'WORKFLOW_RUNNING',
      movingNodeId: 'MOVING_NODE_ID',
    }),
    actionsTypes() {
      const name = this.customer.CustomersFeature.data_source_name;
      const at = actionsTypes(name);
      const trigger_type = this.startSettings.trigger_type;

      return cloneDeep(at).filter((action) => {
        return !(
          action.action_type === 'human_in_the_loop' &&
          trigger_type &&
          trigger_type !== 'manual'
        );
      });
    },
  },
  watch: {
    workflowRunning(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.tab = 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
// @import "@/sass/variables.scss";
@import './automation-sidebar';
</style>
