import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import './plugins/fragment';
import './plugins/meta';
import './plugins/notifications';
import './util/axios';
import './vee-validate';
import './plugins/vue-gapi';
import './filters';


Vue.config.productionTip = false


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
