import { render, staticRenderFns } from "./onedrive.vue?vue&type=template&id=4a42c275&scoped=true"
import script from "./onedrive.vue?vue&type=script&lang=js"
export * from "./onedrive.vue?vue&type=script&lang=js"
import style0 from "./onedrive.vue?vue&type=style&index=0&id=4a42c275&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a42c275",
  null
  
)

export default component.exports