import router from "@/router";

const createTool = async ({ commit, rootGetters, dispatch }, payload) => {
  commit("SET_CREATE_TOOL_LOADING", true);
  try {
    const customer = rootGetters["customer/CUSTOMER"];
    const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
    const data = { ...payload, description: '', input_schema: {type: 'object', properties: {}, required: []} };

    if (customer.customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post("/proxy/prompts-tools", data);
    if (response && response.data.success) {
      router.replace({ path: `/actions/${response.data.tool.id}` });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch("notifications/setError", error.message, { root: true });
  } finally {
    commit("SET_CREATE_TOOL_LOADING", false);
  }
};

export default createTool;
