import Cookies from 'js-cookie';

export const setAuthToken = (data, commit) => {
  const { token, customer, user } = data;
  Cookies.set('token', token);
  Cookies.set('customerId', customer.customer_id);
  Cookies.set('userId', user.id);
  Cookies.set('is_privileged', user?.is_privileged);

  commit('SET_AUTH', true);
  commit(
    'customer/SET_CUSTOMER',
    {
      ...customer,
      user,
    },
    { root: true }
  );
  commit('customer/SET_USER', user, { root: true });
};
