<template>
  <div class="RunWorkflow mt-5">
    <v-row
        dense
        align="center">
      <v-col
          cols="auto"
          class="mr-auto">
        <p class="text-subtitle-1 mb-0">Input Fields</p>
      </v-col>
      <v-col cols="auto">
        <v-btn
            v-if="showAddParamBtn"
            @click="addParam()"
            text
            color="primary"
            dense>
          + Add
        </v-btn>
      </v-col>
    </v-row>
    <v-btn
    class="mt-5"
    color="primary"
    :loading="testingScript"
    @click="showDialog = true">
  Test Workflow
</v-btn>
<v-dialog v-model="showDialog" width="700">
  <v-card>
    <v-card-title class="InputSettings__modal-title">
      JavaScript code editor
    </v-card-title>

    <v-card-text class="InputSettings__modal-content">
      <CodeEditor v-if="showDialog" :value="newInputScript" @input="(val) => onUpdateScript(val)" language="javascript"
                  class="mb-3" :runScript="runTestInput" :placeholder="placeholder" :suggestions="availableInputFields" />
      <p v-if="error" class="error--text">
        {{ error }}
      </p>
      <div v-if="testScriptOutput">
        <p v-if="!isNullOrUndefined(testScriptOutput)" class="text-subtitle-1 mb-3 mt-3">Test Input:</p>
        <json-viewer
            v-if="!isNullOrUndefined(testScriptOutput) && !testingScript"
            :value="testScriptOutput"
            :expand-depth="0"
            boxed
            sort
            copyable
        ></json-viewer>
      </div>
      <p v-if="!isNullOrUndefined(this.testOutput)" class="text-subtitle-1 mb-3 mt-3">Test Result:</p>
      <json-viewer
          v-if="!isNullOrUndefined(this.testOutput) && !testingScript"
          :value="this.testOutput"
          :expand-depth="0"
          boxed
          sort
          copyable
      ></json-viewer>

    </v-card-text>

    <v-divider />

    <v-card-actions class="justify-end py-3 px-4">
      <v-btn color="primary" text @click="showDialog = false">Done</v-btn>
      <v-btn color="primary" :loading="testingScript" @click="runTestInput()">
        Run Input Script
      </v-btn>
      <v-btn v-if="newInput" color="primary" :loading="testingScript" @click="testScript()">
        Test Run Workflow Action
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
      <div
          class="RunWorkflow__param-row mb-6"
          v-for="(param, i) in settings.params"
          :key="i">
        <v-text-field
            class="RunWorkflow__param-row--field mr-2"
            outlined
            dense
            label="Name"
            :value="param.name"
            @input="(val) => {
              onUpdate(val, `settings.params[${i}].name`)
              onUpdate('Object.'+val, `settings.params[${i}].value`)
              updateOutput()
            }" />
        <v-text-field
            class="RunWorkflow__param-row--field mr-2"
            outlined
            dense
            label="Value"
            :value="'Object.'+param.name"
            disabled
        />
        <v-btn
            class="RunWorkflow__param-row--icon-btn"
            icon
            @click="deleteParam(i)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import {getModel,getModelType} from "@/util/actionsModels";
import JsonViewer from "vue-json-viewer";
import CodeEditor from "@/components/code-editor/code-editor.vue";

const { 
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
 } =
    createNamespacedHelpers("workflows/details");

export default {
  name: "workflow-input-settings",
  components: {
    CodeEditor,
    JsonViewer
  },
  props: {
    availableInputFields: [],
  },
  data() {
    return {
      testingScript: false,
      newInput: undefined,
      testOutput: null,
      showDialog: false,
      selectedWorkflow: {},
      newInputScript: '',
      testScriptOutput: '',
      placeholder: '',
      error: '',
      output:'',
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    ...workflowDetailsActions(['executeSingleAction', 'getWorkflowTriggerIO']),
    async runTestInput() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;

        const options = {
          creds: null,
          data: {
            script: this.newInputScript,
          },
          action: 'execute_javascript',
          params: [],
        };
        const data = await this.executeSingleAction(options);
        if (!data || !data.success) {
          throw Error(data && data.message);
        }
        const output = data.data ?? data.result ?? 'no output';
        this.testScriptOutput = output;
        this.newInput = output;
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
    isNullOrUndefined(data) {
      if (data === null || data === undefined) {
        return true;
      }
      return false;
    },
    async testScript() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;
        const params = [];
        if (this.settings.params) {
          this.settings.params.forEach(
              (param) => {
                if(typeof param.value === 'string') {
                  params.push({ name: param.name, value: param.value })
                } else {
                  params.push({ name: param.name, value: param.value.name })
                }
              }
          );
        }

        const options = {
          data: {
            params: params,
            test_mode: true,
            workflow: this.settings.workflow,
          },
          input: this.newInput || {},
          action: this.selectedNode.action_type || 'run_workflow',
        };
        const data = await this.executeSingleAction(options);
        if (data.success) {
          this.output = data.result || 'no output';
          this.testOutput = data.model;
          const output_type = getModelType(data.model);
          this.onUpdate(output_type, 'output_type');
        } else {
          throw Error(data.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
    onUpdateScript(value) {
      this.newInputScript = value;
    },
    addParam() {
      let settings = cloneDeep(this.settings);
      if (!settings.params) {
        settings.params = [];
      }

      settings.params.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteParam(index) {
      const settings = cloneDeep(this.settings);
      settings.params.splice(index, 1);
      if (!settings.params.length) delete settings.params;

      this.onUpdate(settings, 'settings');
    },
    showAddParamBtn() {
      const params = this.settings.params;
      return (
          !params ||
          !params[0] ||
          (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
    updateOutput() {
      let outputModel = {}
      this.settings.params.map((param) => {
        outputModel[param.name] = null;
      })
      const output_type = getModel(outputModel);
      this.onUpdate(output_type, 'output_type');
    }
  },
};
</script>

<style scoped lang="scss">
@import './run-workflow/run-workflow';
</style>
