import router from '@/router';

const updateWorkflow = async ({ commit, rootGetters, dispatch }, payload) => {
  commit('UPDATE_AUTOMATION_WORKFLOW_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = '/proxy/update-workflow';

    let customer_id = customer.customer_id;

    const data = { ...payload, withoutVersionUpdate: true };

    if (customer_id === 0) {
      data.customer_id = selected_customer.customer_id;
    }

    const response = await window.axios.post(url, data);

    if (response && response.data.success) {
      const search = router.currentRoute.query.search;
      const page = router.currentRoute.query.page;
      const archived = router.currentRoute.query.archived;
      dispatch('fetchWorkflows', { search, page, archived });
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    // stuff here
    commit('UPDATE_AUTOMATION_WORKFLOW_LOADING', false);
  }
};

export default updateWorkflow;
