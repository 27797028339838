<template>
  <div class="SqlSettings">
    <v-dialog v-model="showDialog" width="700">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mt-4 mb-4" v-on="on">
          Open SQL query editor
        </v-btn>
      </template>

      <v-card>
        <v-card-title primary-title class="SqlSettings__modal-title">
          SQL query editor
        </v-card-title>

        <v-card-text class="SqlSettings__modal-content">
          <CodeEditor
              v-if="showDialog"
              :value="selectedNode.settings.query"
              @input="(val) => onUpdate(val, 'settings.query')"
              language="sql"
              :runScript="executeQuery"
              :placeholder="placeholder"
              :suggestions="availableInputFields"
          />

          <div v-if="this.output">
            <p class="text-subtitle-2 mb-0">Output</p>
            <json-viewer
                v-if="this.output"
                :value="this.output"
                expanded
                boxed
                sort
                copyable></json-viewer>
          </div>
        </v-card-text>

        <v-divider />


        <v-card-actions class="SqlSettings__modal-actions justify-end">
          <v-btn color="primary" text @click="showDialog = false">
            Close
          </v-btn>
          <v-btn
              color="primary"
              :loading="testingScript"
              @click="showTestInputDialog = true">
            Provide Test Input
          </v-btn>
          <v-btn color="primary" @click="executeQuery()" :loading="executing">
            Execute
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showTestInputDialog" width="700">
      <v-card>
        <v-card-title class="InputSettings__modal-title">
          Provide Test Input
        </v-card-title>

        <v-card-text class="InputSettings__modal-content">
          <CodeEditor v-if="showTestInputDialog" :value="newInputScript" @input="(val) => onUpdateTestInputScript(val)" language="javascript"
                      class="mb-3" :runScript="runTestInput" :placeholder="placeholder" :suggestions="availableInputFields" />
          <p v-if="error" class="error--text">
            {{ error }}
          </p>
          <div v-if="testScriptOutput">
            <p v-if="!isNullOrUndefined(testScriptOutput)" class="text-subtitle-1 mb-3 mt-3">Test Input:</p>
            <json-viewer
                v-if="!isNullOrUndefined(testScriptOutput) && !testingScript"
                :value="testScriptOutput"
                :expand-depth="0"
                boxed
                sort
                copyable
            ></json-viewer>
          </div>
          <p v-if="!isNullOrUndefined(this.output) && this.output !== ''" class="text-subtitle-1 mb-3 mt-3">Test Result:</p>
          <json-viewer
              v-if="!isNullOrUndefined(this.output) && this.output !== ''&& !testingScript"
              :value="this.output"
              :expand-depth="0"
              boxed
              sort
              copyable
          ></json-viewer>

        </v-card-text>

        <v-divider />

        <v-card-actions class="justify-end py-3 px-4">
          <v-btn color="primary" text @click="showTestInputDialog = false">Done</v-btn>
          <v-btn color="primary" :loading="testingScript" @click="runTestInput()">
            Run Input Script
          </v-btn>
          <v-btn v-if="newInput" color="primary" :loading="executing" @click="executeQuery(true)">
            Test Connector
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { getModelFromSql } from '@/util/actionsModels';
import CodeEditor from '@/components/code-editor/code-editor.vue';

const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

import JsonViewer from 'vue-json-viewer';
import 'vue-json-viewer/style.css';

export default {
  name: 'sql-settings',
  components: {
    CodeEditor,
    JsonViewer,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      error: '',
      testingScript: false,
      showTestInputDialog: false,
      newInputScript: '(() => {\n' +
          '  return {\n' +
          '  }\n' +
          '})()',
      testScriptOutput: '',
      newInput: undefined,
      testOutput: null,
      output: null,
      showDialog: false,
      executing: false,
      placeholder: `
      Example:
      select *
      from patients_table
      where patient_id = '{{patient.id}}'
      `,
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selected_customer: 'SELECTED_CUSTOMER',
    }),
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    ...workflowDetailsActions(['executeSingleAction']),
    isNullOrUndefined(data) {
      if (data === null || data === undefined) {
        return true;
      }
      return false;
    },
    onUpdateTestInputScript(value) {
      this.newInputScript = value;
    },
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    async runTestInput() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;

        const options = {
          creds: null,
          data: {
            script: this.newInputScript,
          },
          action: 'execute_javascript',
          params: [],
        };
        const data = await this.executeSingleAction(options);
        if (!data || !data.success) {
          throw Error(data && data.message);
        }
        const output = data.data ?? data.result ?? 'no output';
        this.testScriptOutput = output;
        this.newInput = output;
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
    async executeQuery(withTestInput = false) {
      this.executing = true;

      try {
        const options = {
          creds: this.settings.authenticationId,
          data: this.settings,
          action: 'execute_raw_query',
        };
        if (withTestInput) {
          options.input = this.newInput;
        }
        const data = await this.executeSingleAction(options);
        if (data && data.success) {
          const output_type = this.getModel(this.settings.query, data.model);
          this.onUpdate(output_type, 'output_type');

          this.output = data.data || 'no output';

        } else {
          throw Error(data && data.message ? data.message : 'Error');
        }
      } finally {
        this.executing = false;
      }
    },
    getModel(query, baseModel) {
      const tableName = query.match(/from\s"*(\w*)"*/i)[1];
      return getModelFromSql(tableName, baseModel);
    },
  },
};
</script>

<style scoped lang="scss">
@import './sql-settings';
</style>
