import { render, staticRenderFns } from "./js-settings.vue?vue&type=template&id=4d8b6531&scoped=true"
import script from "./js-settings.vue?vue&type=script&lang=js"
export * from "./js-settings.vue?vue&type=script&lang=js"
import style0 from "./js-settings.vue?vue&type=style&index=0&id=4d8b6531&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d8b6531",
  null
  
)

export default component.exports