const deleteWorkflowVersion = async ({ commit, dispatch }, data) => {
    commit('SET_DELETE_WORKFLOW_VERSION_LOADING', true);
    try {
        let url = `/proxy/delete-workflow-version/`;
   
        data = { ...data };
        const response = await window.axios.post(url, data);

        if (response && response.data.success) {
            const { success } = response.data;
            return success;
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch('notifications/setError', error.message, { root: true });
    } finally {
        commit('SET_DELETE_WORKFLOW_VERSION_LOADING', false);
    }
};



export default deleteWorkflowVersion;
